import { STRINGS } from '@/interfaces/SmartLink/General';

const STRINGS_TYPES:STRINGS = {
    1   : 'All',
    2   : 'Distribucion',
    4   : 'PMP-AP',
    5   : 'PMP',
    6   : 'Acceso',
    7   : 'Solar Trailer',
    8   : 'Tower',
    9   : 'Shovel',
    10  : 'Cliente',
    11  : 'Haultruck',
    12  : 'Driller',
    13  : 'Front Loader',
    14  : 'Watertruck',
    15  : 'OTRO',
    16  : 'OK',
    17  : 'ALARM',
    18  : 'ALERT',
    19  : 'DOWN',
    20  : 'Caidos',
    21  : 'Todos'
}


const STRINGS_STATUS ={
    Ok      : 'Ok',
    Alert   : 'Alert',
    Alarm   : 'Alarm',
    Down    : 'Down',
    Nodata  : 'Nodata',
    OKMayus : 'OK',
    ALEMAYS : 'ALERT',
    ALAMAYS : 'ALARM',
    DOWMAYS : 'DOWN',
}

const STRINGS_LTE = {
    Device      : 'Device',
    vmnic0      : 'vmnic0',
    vmnic1      : 'vmnic1',
    vmnic2      : 'vmnic2',
    vmnic3      : 'vmnic3',
    Link        : 'Link',
    Traditional : 'Traditional',
    vSwitch0    : 'vSwitch0',
    vSwitch1    : 'vSwitch1',
    vSwitch2    : 'vSwitch2',
    vSwitch3    : 'vSwitch3',
    vSwitch4    : 'vSwitch4',
    Virtual     : 'Virtual',
    vmk0        : 'vmk0',
    vmk1        : 'vmk1',
    rajant0     : 'rajant0',
    cif0        : 'cif0',
    ether1      : 'ether1',
    ether2      : 'ether2',
    ether3      : 'ether3',
    lte1        : 'lte1',
    "vpn-hc"    : 'vpn-hc',
    "lte3-cl"   : 'lte3-cl',
    bridge1     : 'bridge1',
    "SW_PROD"   : 'SW_PROD'
}



const STRINGS_SERVER ={
    Device      : 'Device',
    vmnic0      : 'vmnic0',
    vmnic1      : 'vmnic1',
    vmnic2      : 'vmnic2',
    vmnic3      : 'vmnic3',
    Link        : 'Link',
    Traditional : 'Traditional',
    vSwitch0    : 'vSwitch0',
    vSwitch1    : 'vSwitch1',
    vSwitch2    : 'vSwitch2',
    vSwitch3    : 'vSwitch3',
    vSwitch4    : 'vSwitch4',
    Virtual     : 'Virtual',
    vmk0        : 'vmk0',
    vmk1        : 'vmk1',
}

const STRINGS_HTML = {
    LTE         : 'LTE',
    SERVER      : 'SERVER',
    cisco       : 'CISCO',
    Mikrotik    : 'Mikrotik',
    predict     : 'predict',
    predictsnr  : 'predictsnr',
    All         : 'All',
    OK          : 'OK',
    Alert       : 'Alert',
    Alarm       : 'Alarm',
    Down        : 'Down',
    instamesh   : 'instamesh',
    JR3         : 'JR3',
    LX5         : 'LX5',
    HNT         : 'HNT',
    pmpap       : 'PMP-AP',
    pmpsm       : 'PMP',
    ptp         : 'PTP',
    latency     : 'latency',
    quality_lap : 'quality_lap',
    Status      : 'Status',
    rssi        : 'rssi',
    rsrp        : 'rsrp',
    rsrq        : 'rsrq',
    time        : 'yyyy-MM-dd HH:mm:00'
}


const STRINGS_LABELS = {
    all         : '1',
    distri      : '2',
    network     : '3',
    pmpAPValue  : '4',
    pmpSMValue  : '5',
    acceso      : '6',
    solaTrai    : '7',
    tower       : '8',
    shovel      : '9',
    cliente     : '10',
    haultruck   : '11',
    driller     : '12',
    frontLoad   : '13',
    watertru    : '14',
    others      : '15',
    ok          : '16',
    alarm       : '17',
    alert       : '18',
    Rajant      : 'Rajant',
    Cambium     : 'Cambium',
    Mimosa      : 'Mimosa',
    PMPAP1      : 'PMP-AP-01',
    PMPAP2      : 'PMP-AP-02',
    PMPAP3      : 'PMP-AP-03',
    PMPAP4      : 'PMP-AP-04',
    PMPAP5      : 'PMP-AP-05',
    none        : 'APT_STATE_NONE',
    slave       : 'APT_STATE_SLAVE',
    link        : 'APT_STATE_LINK',
    master      : 'APT_STATE_MASTER',
    eth0        : 'eth0', 
    poor        : 'Poor',
    RX          : 'RX',
    unset       : '<unset>',
    heatmap     : 'heatmap', 
    add         : 'ADD',
    edit        : 'EDIT',
    ips         : 'IPS',
    apn         : 'APN',
    delete      : 'DELETE',
    reboot      : 'rebootdevice',
    band        : 'band',
    detail      : 'detail'
}

const STRINGS_VIS_PAGES = {
    topology        :   'topology',
    status          :   'status',
    statusalarm     :   'statusalarm',
    kpi             :   'kpi',
    predicts        :   'predicts',
    predictsnr      :   'predictsnr',
    management      :   'management',
    lte             :   'lte',
    dashboard       :   'dashboard',
    dashboard2      :   'dashboard2',
    servers         :   'servers',
    alarm           :   'alarms',
    map             :   'map',
    heatmap         :   'heatmap',
    newkpi          :   'newkpi',
    instamesh       :   'instamesh',
    snrmaps         :   'snrmaps',
    admincsv        :   'admincsv',
    searchmaps      :   'searchmaps',
    costmaps        :   'costmaps',
    mapCostSQL      :   'mapCostSQL',
    cost_inst       :   'cost_inst',
    latency         :   'latency',
    quality_lap     :   'quality_lap',
    quality_snr     :   'quality_snr',
    snr_vertical    :   'snr_vertical',
    snr_horizontal  :   'snr_horizontal',
    pack_inst       :   'pack_inst',
    temp_inst       :   'temp_inst',
    wire_inst       :   'wire_inst',
    operability     :   'operability',
    dbstatus        :   'dbstatus',
    history         :   'history',
    wiredpeers      :   'wiredpeers',
    wirelesspeers   :   'wirelesspeers',
    testeo          :   'testeo',
    report          :   'report',
    alarmreport     :   'alarmreport',
    allLatency      :   'allLatency',
    presentation    :   'presentation',
    dbmaintenance   :   'dbmaintenance',
    YAMLMaintenance :   'YAMLMaintenance',
    drive           :   'drive',
    driveraj        :   'driveraj',
    lastconection   :   'lastconection',
    // managementG     :   'managementG',
    monitoring      :   'monitoring',
    // test            :   'test',
    // add             :   'add',
    // remove          :   'remove',
    // show            :   'show',
    // change          :   'change',
    allview         :   'allview',
    // admin           :   'admin',
    // adminusers      :   'adminusers',
    allConfig       :   'allConfig',
    equipKey        :   'equipKey',
    usersKey        :   'usersKey',
}

const STRING_KPI = {
    latency     : 'latency',
    quality     : 'quality_lap',
    qualitysnr  : 'quality_snr',
    temp        : 'temp_inst',
    operability : 'operability',
    kpisnr      : 'kpisnr',
    wired       : 'wiredpeers',
    wireless    : 'wirelesspeers'
}

const STRING_GENERAL = {
    client      : 'client',
    distri      : 'distribution',
    access      : 'access',
}

export default {
    STRINGS_TYPES,
    STRINGS_STATUS,
    STRINGS_LTE,
    STRINGS_SERVER,
    STRINGS_HTML,
    STRINGS_LABELS,
    STRINGS_VIS_PAGES,
    STRING_KPI,
    STRING_GENERAL,
}