import axios from 'axios'
import { createStore } from 'vuex'
import CONSTAPIS from '@/constants/apis'

export default createStore({
  state: {
    sesion:{
      user    : '',
      rol     : ''
    },
    valueKPI        : '',
    valueDRIVE      : '',
    arrayListItems  : [],
    arrayOIDSItems  : [],
    arrayTotalKPI   : [],
    oneInventory    : {},
    overlayLoading  : false,
    snackbarAdd     : false,
    snackbarEdit    : false,
    snackbarDelete  : false,
    snackbarError   : false,
    snackbarmsg     : '',
    alertNumber     : 0,
    drawer          : true,
    showVirMac      : true,
    dbStatusInfo    : {},
    ieoDistri       : '',
    ieoAcceso       : '',
    ieoClient       : '',
    valueView       : '',
    showDrawerTopo  : false,
    appBarPage      : 'loginPage',
    showAllViews    : false,
    arrayInterface  : [],
    arrayLTE        : [],
    usersGeneral    : []
  },    
  mutations: {

    setAppBarPage (state, page){
      state.appBarPage = page
    },
    setIEODistri (state, IEOdistri){
      state.ieoDistri = IEOdistri
    },
    setIEOAcceso (state, IEOAcceso){
      state.ieoAcceso = IEOAcceso
    },
    setIEOClient (state, IEOCliente){
      state.ieoClient = IEOCliente
    },

    setOneInventory(state,value){
      state.oneInventory = value
    },
    
    setUserSesion(state,value){
      state.sesion.user = value
    },
    
    setUserRol(state,value){
      state.sesion.rol = value
    },

    setTotalKPI(state,value){
      state.arrayTotalKPI = value
    },

    postKPIValue(state,value){
      state.valueKPI = value
    },

    postDRIVEValue(state,value){
      state.valueDRIVE = value
    },
    addListItems(state,value){
      state.arrayListItems = value
    },    
    addOIDListItems(state,value){
      state.arrayOIDSItems = value
    },    
    showOverlay(state,value){
      state.overlayLoading = value
    },
    setSnackAdd(state, stateSnack){
      state.snackbarAdd = stateSnack
    },
    setSnackEdit(state, stateSnack){
      state.snackbarEdit = stateSnack
    },
    setSnackDelete(state, stateSnack){
      state.snackbarDelete = stateSnack
    },
    setSnackError(state, stateSnack){
      state.snackbarError = stateSnack
    },
    setSnackMSG(state, msg){
      state.snackbarmsg = msg
    },
    setAlertNumber(state,value){
      state.alertNumber = value
    },
    showDrawer(state){
      state.drawer = !state.drawer
    },
    noShowDrawer(state){
      state.drawer = false
    },
    yesShowDrawer(state){
      state.drawer = true
    },
    showDrawerTopo(state){
      state.showDrawerTopo = true
    },
    noShowDrawerTopo(state){
      state.showDrawerTopo = false
    },
    setDBStatusInfo(state,value){
      state.dbStatusInfo = value
    },
    setValueView(state,value){
      state.valueView = value
    },
    setVirtualMachine(state, value){
      state.showVirMac = value;
    },
    setFinalArrayInterfaces (state, array){
      state.arrayInterface = array
    },
    setFinalArrayLTE (state, array){
      state.arrayLTE = array
    },
    setUsersGeneral (state, users){
      state.usersGeneral = users
    }
  },
  actions: {
    async getInvetoryData({commit}){
      try {
         await axios.get(CONSTAPIS.APIS.general.inventory)
        .then((response) =>{
          commit('addListItems', response.data.body)
        });
        
      } catch (error) {
        console.log(error)
      }
    },  

    async getInvetoryOIDData({commit}){
      try {
         await axios.get(CONSTAPIS.APIS.general.inventoryOID)
        .then((response) =>{
          commit('addOIDListItems', response.data.body)
        });
        
      } catch (error) {
        console.log(error)
      }
    },  

    async getStatusDBData({commit}){
      try {
        await axios.get(CONSTAPIS.APIS.general.getDBStatus).then((res)=>{       
          commit('setDBStatusInfo', res.data[0])
        });
        
      } catch (error) {
        console.log(error)
      }
    },  

    async showSnackBar({commit}, {type,msg}){
      commit(type, true);
      commit('setSnackMSG', msg);

      setTimeout(() => {
          commit(type, false);
          commit('setSnackMSG','');
      }, 4000);
    },
    
    async getIpInterfaces({commit}){
      try {
        await axios.get(CONSTAPIS.APIS.monitoreo.showip2).then(items=>{
          
          const itemsFinalInterface:any = [];
          const finalUsers:any          = [];
 
              itemsFinalInterface.value    = [];
              finalUsers.value    = [];
              const re              = /'/gi;
              const itemAPI         = items.data;

              itemAPI.forEach((item:any, index:number)=> {
                  
                  if( index % 3 === 2 || index === 1 ) {
                      const finalArray      = item.replace(re ,`"`);
              
                      const preFinalItems   = JSON.parse(finalArray);
                      
                      preFinalItems.forEach((item:any) => {
                        if(index === 1) {
                          finalUsers.value.push({
                            ip:item[0],
                            user:item[1],
                            pass:item[2]
                          })
                        }else{
                          itemsFinalInterface.value.push(item)
                        }                   
                      })
                  }

              })
              commit('setUsersGeneral', finalUsers.value)
              commit('setFinalArrayInterfaces', itemsFinalInterface.value);
          // } 
      });
        
      } catch (error) {
        console.log(error)
      }
    },  
    async getIpLTE({commit}){
      try {
        await axios.get(CONSTAPIS.APIS.monitoreo.showapn2).then(items=>{
          
          const itemsFinalLTE:any = [];
 
          if(!items.data.error){

            itemsFinalLTE.value    = [];
              const re              = /'/gi;
              const itemAPI         = items.data.body;

              itemAPI.forEach((item:any)=> {
                  
                const finalArray      = item.replace(re,`"`); 
        
                const preFinalItems   = JSON.parse(finalArray);
                
                preFinalItems.forEach((item:any) => {
                    
                  itemsFinalLTE.value.push(item)
                })

              })
              
              commit('setFinalArrayLTE', itemsFinalLTE.value);
          } 
      });
        
      } catch (error) {
        console.log(error)
      }
    },  
  },
  modules: {
  }
})
