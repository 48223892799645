const project = process.env.VUE_APP_NAME_PROJECT;

export function getNowTime(){
    let nowTime = new Date().getTime();
    if(project === 'SmartLinkMasterFront'){
        nowTime = new Date('2024-05-01 17:15:00').getTime();
    }
    return nowTime;
}

export function getFinDate(){
    let finDate = new Date();
    if(project === 'SmartLinkMasterFront'){
        finDate = new Date('2024-05-31 17:15:00');
    }
    return finDate;
}

export function getLatitude(latitud:string){
    let finalLatitud    = 0;
    const gradeLat      = parseFloat((latitud??'0').slice(0,2));
    const minuteLat     = (parseFloat((latitud??'0').slice(2,-1))/60);
    finalLatitud        = gradeLat + minuteLat;
    return finalLatitud
}

export function getLongitude(longitud:string){
    let finalLongitud   = 0;
    const gradeLng      = parseFloat((longitud??'0').slice(0,3));
    const minuteLng     = (parseFloat((longitud??'0').slice(3,-1))/60);
    finalLongitud       = (gradeLng + minuteLng) * (-1);
    return finalLongitud
}

export function getLatLngPrev(lat: string, lng: string){
    let position = {lat: 0, lng: 0};
    if(project === 'SmartLinkAntamina' || project === 'SmartLinkAntucoya'){
        position = {lat: parseFloat(lat), lng: parseFloat(lng)};
    }        
    if(project === 'SmartLinkMaster' || project === 'SmartLinkMasterFront'){
        const latSM     = (`${lat.slice(0,2)}.${lng.slice(2)}`);
        const lngSM     = (`${lat.slice(0,4)}.${lng.slice(4)}`);
        position        = {lat: parseFloat(latSM), lng: parseFloat(lngSM)};
    }
    return position;
}

export function getTimeZone(){
    let timezone = '';
    if(project === 'SmartLinkAntamina'){
        timezone = 'America/Bogota';
    }        
    if(project === 'SmartLinkMaster' || project === 'SmartLinkMasterFront' || project === 'SmartLinkCananea' ){
        timezone = 'America/Hermosillo';
    }
    if(project === 'SmartLinkAntucoya' || project === 'SmartLinkZaldivar'){
        timezone = 'America/Santiago';
    }
    return timezone;
}