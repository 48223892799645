import { ref } from "vue";

export function useTypingEffect(text:any, speed = 5) {
  const displayedText = ref("");
  let interval:any = null;

  const startTyping = () => {
    displayedText.value = "";
    let currentIndex = 0;

    clearInterval(interval);
    interval = setInterval(() => {
      if (currentIndex < text.length) {
        displayedText.value += text[currentIndex];
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, speed);
  };

  return { displayedText, startTyping };
}