
const latitud   = parseFloat(process.env.VUE_APP_MAP_LAT)
const longitud  = parseFloat(process.env.VUE_APP_MAP_LNG)
const zoom      = parseFloat(process.env.VUE_APP_MAP_ZOOM)

const APIKEYGOOGLEMAPS = { 
    api     : `${process.env.VUE_APP_MAP_API_KEY}`,
    center  : { lat:latitud, lng:longitud  },
    zoom    : zoom,
    cenNew  : [latitud, longitud]
}

export default {
    APIKEYGOOGLEMAPS,
    latitud,
    longitud
}