<template>
    <div class="bodyPage">

        <v-icon class="imgcenter">
            mdi-tools
        </v-icon>
        
        <p class="txtblue">Our website is <a class="txtyellow">going through a maintenance</a> and will be available soon.<br /></p>
        <p class="txtwhite"><strong>Thank You!</strong> for visiting.</p>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
.txtblue {
    font-size: 16px;
    font-weight: 400;
    color: #0094ff;
}
.txtwhite {
    font-size: 16px;
    color: #fff;
}
.txtyellow {
    font-size: 16px;
    color: #ffd400;
}
.imgcenter {
    font-size: 210px;
    margin: 20px auto;
    color:#00AEF0;
}

.bodyPage {
    padding: 8%;
    font-family: verdana;
    text-align: center;
    height: 100vh;
    /* margin-top: 1%; */
    line-height: 30px;
    background-color:#000;
}
</style>