const solarTrailer = `
<g transform="translate(-85.000000,410.000000) scale(0.060000,-0.061000)"
fill="#FF00FF" stroke="none">
<path d="M3695 6649 c-16 -4 -71 -36 -121 -71 l-93 -62 86 -125 c155 -226 174
-251 186 -244 7 3 24 15 40 25 l27 19 0 -685 0 -686 -558 0 c-620 0 -593 3
-611 -68 -5 -20 -53 -242 -106 -492 -52 -250 -121 -572 -152 -715 -51 -237
-56 -274 -61 -417 l-4 -157 25 -25 26 -26 716 0 715 0 0 -164 0 -165 -240 -3
c-208 -3 -241 -5 -256 -20 -13 -13 -18 -41 -22 -117 l-5 -101 -238 0 c-210 0
-239 2 -239 16 0 11 -23 -1 -77 -38 l-78 -53 -3 -68 c-2 -37 0 -67 3 -67 4 0
32 18 62 40 l56 40 1224 0 1224 0 59 -40 c32 -22 62 -40 65 -40 3 0 5 30 3 67
l-3 68 -77 53 c-55 37 -78 49 -78 38 0 -14 -29 -16 -239 -16 l-239 0 -4 100
c-5 143 13 134 -278 138 l-240 3 0 165 0 164 713 0 c553 0 718 3 737 13 25 12
25 15 28 151 2 122 -1 158 -29 295 -47 239 -278 1341 -290 1384 -7 26 -18 41
-35 47 -15 6 -253 10 -585 10 l-559 0 0 690 c0 380 3 690 6 690 3 0 26 -14 50
-31 25 -17 47 -29 48 -27 7 8 243 354 249 364 9 16 -161 131 -214 145 -96 26
-204 -20 -256 -108 -23 -40 -28 -59 -28 -113 1 -42 7 -76 18 -97 16 -29 17
-92 17 -773 l0 -740 -65 0 -65 0 0 725 0 725 26 39 c31 47 41 128 24 190 -15
56 -89 132 -146 149 -47 14 -96 14 -139 1z m120 -103 c64 -27 93 -112 61 -174
-13 -24 -103 -99 -110 -91 -42 58 -136 200 -136 206 0 8 42 38 85 61 28 16 59
15 100 -2z m529 -25 l46 -32 -72 -104 -72 -105 -25 16 c-49 31 -81 68 -93 105
-9 32 -8 44 11 81 39 82 120 97 205 39z m-848 -1938 c-4 -60 -9 -145 -13 -190
l-6 -83 -398 0 c-314 0 -397 3 -394 13 2 6 20 88 39 180 20 93 38 173 42 178
3 5 160 9 371 9 l366 0 -7 -107z m878 60 c3 -27 8 -91 11 -143 3 -52 8 -116
11 -142 l6 -48 -402 0 -401 0 5 33 c3 17 8 75 11 127 3 52 8 123 11 158 l6 62
368 0 368 0 6 -47z m866 25 c5 -13 23 -90 40 -173 17 -82 32 -158 34 -167 4
-17 -20 -18 -393 -18 l-397 0 -12 173 c-7 94 -12 180 -12 190 0 16 24 17 365
17 l365 0 10 -22z m-1774 -525 c-3 -21 -8 -90 -11 -153 -3 -63 -8 -132 -11
-152 l-6 -38 -429 0 c-352 0 -429 2 -429 13 0 30 73 343 82 355 8 9 102 12
410 12 l400 0 -6 -37z m947 -45 c4 -46 9 -131 13 -190 l7 -108 -432 0 c-404 0
-431 1 -431 18 0 9 5 95 12 190 l12 172 406 0 407 0 6 -82z m931 73 c7 -10 76
-337 76 -357 0 -12 -67 -14 -429 -14 l-428 0 -6 58 c-4 31 -9 107 -12 167 -3
61 -8 120 -11 133 l-4 22 404 0 c223 0 407 -4 410 -9z m-1914 -518 c0 -10 -5
-96 -12 -190 l-12 -173 -205 0 c-237 0 -254 -4 -259 -63 -6 -71 -85 -67 1333
-67 l1265 0 0 -55 0 -55 -1540 0 -1540 0 0 55 0 55 138 0 c139 0 174 8 195 41
15 24 1 64 -28 77 -16 7 -79 12 -160 12 -74 0 -135 2 -135 4 0 8 71 341 76
359 5 16 35 17 445 17 411 0 439 -1 439 -17z m1014 -5 c3 -13 8 -70 11 -128 3
-58 8 -133 11 -167 l6 -63 -472 0 -472 0 6 48 c3 26 8 90 11 142 3 52 8 116
11 143 l6 47 439 0 439 0 4 -22z m1048 -158 c20 -96 37 -181 38 -188 0 -10
-97 -12 -467 -10 l-468 3 -12 155 c-6 85 -12 170 -12 188 l-1 32 442 -2 442
-3 38 -175z m-1432 -735 l0 -165 -55 0 -55 0 0 165 0 165 55 0 55 0 0 -165z
m520 -350 l0 -55 -580 0 -580 0 0 55 0 55 580 0 580 0 0 -55z"/>
<path d="M2500 2729 c-37 -15 -60 -39 -60 -64 0 -16 19 -34 70 -69 38 -25 72
-46 75 -46 3 0 5 43 5 95 l0 95 -32 -1 c-18 0 -44 -5 -58 -10z"/>
<path d="M2650 2641 l0 -102 78 55 c83 59 89 72 48 114 -15 15 -39 24 -74 29
l-52 6 0 -102z"/>
<path d="M5254 2730 c-22 -8 -64 -56 -64 -71 0 -10 138 -109 152 -109 4 0 8
43 8 95 l0 95 -37 -1 c-21 0 -48 -4 -59 -9z"/>
<path d="M5410 2645 c0 -52 3 -95 6 -95 15 0 144 97 144 108 0 43 -58 82 -120
82 l-30 0 0 -95z"/>
<path d="M2432 2515 l3 -67 78 -55 77 -54 0 68 0 68 -53 36 c-28 20 -65 44
-80 54 l-28 18 3 -68z"/>
<path d="M2728 2528 l-78 -53 0 -68 0 -68 76 51 c41 28 77 57 80 64 3 7 4 39
2 70 l-3 57 -77 -53z"/>
<path d="M5192 2513 l3 -68 78 -53 78 -54 -3 68 -3 68 -60 43 c-103 74 -96 74
-93 -4z"/>
<path d="M5488 2529 l-78 -52 0 -68 0 -68 80 55 80 55 0 65 c0 35 -1 64 -2 64
-2 0 -38 -23 -80 -51z"/>
<path d="M2430 2312 l0 -69 53 -35 c28 -19 64 -43 80 -52 l27 -18 0 68 0 69
-52 35 c-29 19 -65 43 -80 53 l-28 19 0 -70z"/>
<path d="M5488 2328 l-78 -53 0 -68 c0 -52 3 -66 13 -61 41 24 125 84 135 97
8 10 12 38 10 77 l-3 61 -77 -53z"/>
<path d="M2430 2106 l0 -64 74 -51 c41 -28 77 -51 80 -51 3 0 6 29 6 65 l0 64
-75 51 c-41 27 -77 50 -80 50 -3 0 -5 -29 -5 -64z"/>
<path d="M2723 2118 l-73 -52 0 -63 c0 -35 2 -63 5 -63 3 0 39 23 80 50 l75
51 0 64 c0 36 -3 65 -7 65 -5 -1 -41 -24 -80 -52z"/>
<path d="M5190 2107 l0 -65 67 -47 c96 -67 93 -68 93 7 l0 65 -72 49 c-40 27
-76 51 -80 52 -5 2 -8 -26 -8 -61z"/>
<path d="M5483 2119 l-73 -50 0 -64 c0 -36 2 -65 5 -65 3 0 39 23 80 52 l75
52 0 63 c0 35 -3 63 -7 62 -5 0 -41 -22 -80 -50z"/>
<path d="M2432 1907 c5 -87 31 -117 107 -125 l51 -5 0 45 c0 49 7 42 -134 133
l-29 19 5 -67z"/>
<path d="M2727 1918 c-76 -52 -77 -53 -77 -97 l0 -44 52 5 c42 4 57 11 80 37
24 27 28 40 28 91 0 33 -1 60 -3 60 -2 0 -38 -24 -80 -52z"/>
<path d="M5190 1911 c0 -52 4 -65 28 -92 23 -26 38 -33 80 -37 l52 -5 0 44
c-1 46 -6 52 -132 134 l-28 17 0 -61z"/>
<path d="M5483 1917 c-71 -50 -73 -52 -73 -94 0 -42 1 -43 34 -43 82 0 126 48
126 138 0 28 -3 52 -7 51 -5 0 -41 -23 -80 -52z"/>
</g>
`

const pmpap = `
<g transform="translate(-330.000000,635.000000) scale(0.1200,-0.1200)"
fill="#000000" stroke="none">
<path d="M3880 5174 c-331 -42 -586 -166 -796 -385 -97 -102 -151 -179 -209
-302 -152 -316 -152 -658 0 -974 58 -123 112 -200 209 -302 180 -188 395 -306
658 -362 145 -32 371 -32 516 0 263 56 478 174 658 362 97 102 151 179 209
302 152 316 152 658 0 974 -58 123 -112 200 -209 302 -233 243 -537 376 -881
386 -66 2 -136 1 -155 -1z m363 -145 c409 -81 740 -388 843 -784 23 -86 26
-121 26 -245 0 -124 -3 -159 -26 -245 -106 -406 -441 -708 -875 -791 -125 -24
-319 -22 -449 5 -418 87 -743 388 -848 786 -23 86 -27 120 -26 245 0 124 4
160 26 245 130 497 609 836 1141 808 50 -3 134 -14 188 -24z"/>
<path d="M3701 4368 c-5 -13 -55 -135 -111 -273 -56 -137 -106 -260 -111 -272
-8 -23 -7 -23 64 -23 l73 0 21 58 20 57 115 3 114 3 21 -58 20 -58 73 -3 c66
-3 72 -1 66 15 -3 10 -57 143 -119 295 l-112 278 -63 0 c-56 0 -63 -2 -71 -22z
m120 -280 l19 -48 -65 0 c-36 0 -65 2 -65 5 0 8 49 135 57 148 7 11 12 2 54
-105z"/>
<path d="M4100 4095 l0 -295 65 0 65 0 0 110 0 110 63 0 c68 1 127 18 159 47
77 70 77 210 -1 276 -45 38 -90 47 -228 47 l-123 0 0 -295z m245 160 c53 -52
13 -125 -70 -125 l-45 0 0 75 0 75 45 0 c35 0 52 -6 70 -25z"/>
</g>
`

const pmpsm = `
<g transform="translate(-333.000000,640.000000) scale(0.1210000,-0.1210000)"
fill="#000000" stroke="none">
<path d="M3880 5174 c-331 -42 -586 -166 -796 -385 -97 -102 -151 -179 -209
-302 -152 -316 -152 -658 0 -974 58 -123 112 -200 209 -302 180 -188 395 -306
658 -362 145 -32 371 -32 516 0 263 56 478 174 658 362 97 102 151 179 209
302 152 316 152 658 0 974 -58 123 -112 200 -209 302 -233 243 -537 376 -881
386 -66 2 -136 1 -155 -1z m363 -145 c409 -81 740 -388 843 -784 23 -86 26
-121 26 -245 0 -124 -3 -159 -26 -245 -106 -406 -441 -708 -875 -791 -125 -24
-319 -22 -449 5 -418 87 -743 388 -848 786 -23 86 -27 120 -26 245 0 124 4
160 26 245 130 497 609 836 1141 808 50 -3 134 -14 188 -24z"/>
<path d="M3590 4373 c-63 -32 -100 -85 -100 -145 0 -78 35 -119 157 -182 66
-35 78 -45 81 -69 2 -16 -1 -34 -8 -41 -27 -34 -111 -25 -181 20 l-38 24 -30
-54 c-17 -29 -31 -56 -31 -60 0 -4 30 -21 68 -39 59 -28 77 -32 152 -32 71 0
92 4 128 25 59 34 86 80 87 150 0 79 -30 116 -137 170 -95 47 -118 66 -118 91
0 22 37 49 67 49 13 0 45 -9 73 -19 l51 -19 24 49 c14 26 25 51 25 54 0 14
-99 47 -155 51 -52 5 -67 2 -115 -23z"/>
<path d="M3966 4368 c-3 -13 -14 -133 -26 -268 -11 -135 -22 -257 -25 -272
l-4 -28 69 0 70 0 1 38 c0 20 6 100 13 177 l11 140 21 -55 c11 -30 40 -110 64
-178 l43 -123 40 3 40 3 61 175 c46 133 62 168 64 145 2 -16 9 -95 15 -175
l12 -145 68 -3 69 -3 -6 38 c-3 21 -15 154 -27 296 l-22 257 -72 0 -72 0 -64
-165 c-34 -91 -66 -162 -70 -158 -5 5 -35 78 -68 163 l-59 155 -71 3 c-66 3
-71 2 -75 -20z"/>
</g>
`

const haultruck = `
<g transform="translate(-90.000000,375.000000) scale(0.16000,-0.150000)"
fill="#000000" stroke="none">
<path d="M1586 2178 c-8 -13 -34 -62 -57 -110 l-43 -88 -242 0 c-133 0 -249
-3 -258 -6 -21 -8 -21 -40 0 -48 21 -8 498 -8 519 0 9 4 41 55 71 115 l54 109
124 0 c69 0 127 4 130 9 4 5 3 16 0 25 -5 14 -27 16 -145 16 -133 0 -139 -1
-153 -22z"/>
<path d="M1954 2185 c-12 -30 7 -35 147 -35 76 0 139 -2 139 -5 0 -2 -5 -16
-12 -30 l-11 -25 -232 0 c-174 0 -235 -3 -242 -12 -5 -7 -27 -53 -47 -103 -21
-49 -43 -96 -48 -102 -13 -16 -978 -19 -978 -4 0 5 12 19 26 30 22 17 41 21
109 21 61 0 86 4 95 15 10 12 10 18 0 30 -9 11 -35 15 -102 15 l-90 0 -69 -67
-70 -67 3 -71 3 -70 75 -6 c41 -4 92 -15 113 -25 l37 -18 0 -142 c0 -132 -1
-143 -19 -149 -11 -3 -31 -3 -44 0 -21 6 -35 -2 -72 -36 -39 -36 -46 -48 -41
-70 7 -38 -2 -63 -27 -74 -19 -9 -22 -19 -25 -77 -3 -61 -1 -68 20 -79 32 -17
38 -29 32 -64 -4 -26 2 -37 44 -79 41 -39 53 -46 68 -38 23 13 60 -4 67 -29 4
-16 15 -19 75 -19 64 0 71 2 87 26 14 22 22 25 52 21 29 -5 40 -1 74 30 51 46
64 70 49 88 -8 10 -8 19 0 34 9 17 20 21 60 21 l49 0 3 -52 3 -53 124 -3 c144
-3 154 1 149 65 l-3 43 52 0 c56 0 70 -14 58 -62 -5 -18 4 -33 45 -73 41 -40
54 -48 68 -39 25 15 52 6 62 -22 9 -22 14 -24 84 -24 66 0 75 2 81 20 10 30
40 43 65 28 18 -12 25 -7 72 40 47 47 52 54 40 72 -9 15 -10 26 -2 40 10 18
21 20 133 20 82 0 127 4 135 12 16 16 16 130 0 146 -7 7 -20 12 -30 12 -16 0
-18 10 -18 85 0 78 2 85 20 85 26 0 40 29 40 85 0 71 -12 85 -70 85 l-50 0 0
48 c0 67 -37 174 -59 170 -22 -4 -25 -48 -7 -93 9 -21 16 -57 16 -81 l0 -44
-280 0 -280 0 19 48 c11 26 47 112 80 192 l60 145 148 3 149 3 18 -56 c13 -41
22 -55 36 -55 10 0 22 5 25 10 8 13 -34 142 -49 152 -20 14 -344 9 -356 -4 -7
-7 -51 -107 -99 -223 l-87 -210 -37 -3 c-20 -2 -37 -2 -37 0 0 2 47 116 104
253 l104 250 236 3 236 2 32 64 c47 97 49 96 -152 96 -140 0 -172 -3 -176 -15z
m-1044 -445 c0 -38 -2 -70 -5 -70 -3 0 -45 18 -94 40 -62 28 -102 40 -135 40
-44 0 -46 1 -46 30 l0 30 140 0 140 0 0 -70z m230 -50 c0 -66 -2 -120 -4 -120
-9 0 -152 63 -163 72 -9 7 -13 38 -13 89 l0 79 90 0 90 0 0 -120z m220 -51 c0
-94 -4 -169 -8 -167 -5 2 -43 18 -85 37 l-77 35 0 133 0 133 85 0 85 0 0 -171z
m187 -23 l-81 -194 -28 14 -28 15 0 179 0 180 109 0 109 0 -81 -194z m-577
-33 c58 -25 107 -48 109 -50 2 -1 -48 -3 -112 -3 l-117 0 0 50 c0 28 3 50 7
50 4 0 55 -21 113 -47z m381 -168 c67 -30 128 -55 135 -55 6 0 22 25 34 55
l22 55 257 0 257 0 54 -55 c30 -30 62 -55 72 -55 16 0 18 -10 18 -85 0 -79 -1
-85 -20 -85 -26 0 -42 38 -26 62 9 15 2 26 -39 68 -43 44 -54 50 -80 46 -33
-6 -48 1 -58 27 -5 14 -19 17 -87 17 -72 0 -80 -2 -80 -19 0 -23 -31 -35 -61
-26 -17 6 -32 -4 -70 -41 -43 -42 -49 -53 -43 -77 8 -40 -10 -57 -62 -57 l-44
0 0 43 c0 68 -1 68 -146 65 l-129 -3 -3 -52 -3 -53 -49 0 c-57 0 -80 23 -60
60 9 17 4 27 -37 71 -41 44 -51 50 -76 45 -33 -7 -67 8 -67 29 0 11 -13 15
-55 15 -54 0 -55 0 -55 30 l0 30 189 0 189 0 123 -55z m1019 24 l0 -31 -95 4
c-88 3 -97 5 -122 31 l-28 27 123 0 122 0 0 -31z m-1460 -89 c0 -14 84 -50
115 -50 26 0 51 -26 47 -50 -4 -23 35 -107 53 -114 19 -7 20 -52 1 -59 -19 -7
-58 -97 -51 -115 8 -20 -25 -53 -45 -47 -21 7 -109 -32 -117 -51 -3 -8 -18
-14 -33 -14 -15 0 -30 6 -33 14 -8 19 -93 57 -112 49 -21 -7 -45 15 -45 42 0
28 -34 110 -49 119 -14 9 -14 66 -1 66 14 0 52 88 48 110 -4 24 20 50 47 50
31 0 115 36 115 50 0 5 14 10 30 10 17 0 30 -5 30 -10z m1017 -4 c10 -17 77
-46 108 -46 24 0 60 -33 51 -48 -8 -14 36 -112 51 -112 19 0 16 -57 -3 -68
-17 -10 -45 -72 -47 -108 -2 -27 -27 -56 -43 -50 -18 7 -105 -28 -116 -48 -11
-19 -68 -22 -68 -3 0 16 -96 58 -116 50 -18 -7 -43 24 -44 53 0 30 -29 97 -46
107 -16 9 -19 67 -4 67 15 0 52 79 48 104 -4 32 19 56 52 56 36 0 99 27 107
45 7 19 59 20 70 1z m393 -71 l0 -85 -30 0 -30 0 0 85 0 85 30 0 30 0 0 -85z
m-850 -170 l0 -145 -85 0 -85 0 0 145 0 145 85 0 85 0 0 -145z m-220 5 c0 -27
-3 -30 -30 -30 -27 0 -30 3 -30 30 0 27 3 30 30 30 27 0 30 -3 30 -30z m338
-2 c3 -25 0 -28 -27 -28 -28 0 -31 3 -31 31 0 27 3 30 28 27 21 -2 28 -9 30
-30z m782 2 l0 -30 -85 0 -85 0 0 30 0 30 85 0 85 0 0 -30z"/>
<path d="M837 1236 c-51 -19 -63 -31 -83 -82 -14 -37 -15 -51 -6 -82 37 -122
204 -138 257 -25 34 71 4 151 -68 184 -49 22 -51 22 -100 5z m107 -77 c34 -40
33 -74 -3 -110 -57 -56 -141 -23 -141 56 0 80 93 115 144 54z"/>
<path d="M1856 1240 c-69 -21 -115 -103 -96 -169 43 -152 270 -123 270 34 0
92 -88 161 -174 135z m92 -70 c46 -43 37 -107 -19 -139 -51 -29 -119 14 -119
76 0 70 87 110 138 63z"/>
<path d="M1885 1828 c-7 -18 -29 -71 -49 -118 -55 -130 -68 -120 159 -120
l195 0 -1 28 c0 15 -17 74 -37 132 l-37 105 -108 3 -108 3 -14 -33z m214 -90
c13 -40 26 -79 28 -85 4 -10 -26 -13 -130 -13 l-135 0 36 85 36 85 71 0 71 0
23 -72z"/>
</g>
`

const driller = `
<g transform="translate(-110, 400) scale(0.065000,-0.065000)"
fill="#000000" stroke="none">
<path d="M2595 6165 c-91 -35 -135 -111 -143 -247 l-5 -88 -212 0 c-254 0
-292 -8 -353 -74 -51 -55 -70 -128 -52 -196 9 -34 26 -58 71 -101 l59 -56 -2
-1218 -3 -1219 -49 -12 c-80 -21 -81 -23 -84 -202 -3 -202 2 -214 102 -227
l49 -7 -7 -92 c-3 -51 -17 -130 -31 -174 -52 -175 -60 -362 -17 -405 22 -21
30 -22 235 -25 242 -4 278 2 297 48 20 49 7 226 -26 345 -19 70 -29 131 -32
201 l-3 101 161 5 c153 5 164 7 217 34 46 24 62 38 82 79 l26 49 3 1153 2
1153 28 -14 c15 -8 187 -175 382 -370 l355 -356 -62 -55 c-75 -67 -141 -164
-179 -265 l-29 -75 -3 -522 -3 -523 -62 0 c-133 0 -257 -51 -352 -144 -98 -96
-148 -216 -147 -355 2 -246 181 -449 427 -482 54 -7 434 -9 1170 -7 1078 3
1091 4 1152 25 89 30 143 64 201 128 60 65 105 154 128 251 29 125 2 245 -82
371 -24 36 -44 66 -44 67 0 1 55 2 123 2 144 1 190 16 241 76 19 21 39 59 45
83 15 61 15 611 -1 672 -14 59 -67 112 -140 142 l-55 22 -7 60 c-8 80 -30 125
-80 161 -52 37 -57 47 -61 114 -7 117 -84 203 -189 212 -139 11 -236 -86 -236
-237 0 -43 -3 -48 -47 -81 -62 -46 -84 -87 -91 -165 l-5 -65 -243 0 -243 0 -3
273 c-3 310 -7 325 -87 382 l-43 30 -269 5 -269 5 -470 470 c-494 494 -497
498 -452 537 9 9 67 41 129 72 62 31 117 65 123 75 5 11 10 76 10 144 0 185
23 171 -298 175 l-260 3 -4 92 c-2 75 -8 102 -29 142 -46 90 -161 135 -254
100z m119 -157 c14 -20 16 -195 16 -1665 l0 -1642 -22 -15 c-12 -9 -42 -16
-65 -16 l-43 0 0 1658 c0 1840 -5 1702 65 1702 24 0 39 -7 49 -22z m-264 -393
l0 -65 -218 0 c-246 0 -262 4 -262 65 0 61 16 65 262 65 l218 0 0 -65z m848
33 c5 -46 -15 -66 -131 -123 -61 -31 -120 -64 -131 -74 -15 -16 -18 -34 -17
-122 1 -112 -10 -177 -31 -190 -7 -5 -34 -9 -60 -9 l-48 0 0 275 0 275 208 -2
207 -3 3 -27z m-1058 -288 c0 -22 -1 -40 -3 -40 -2 0 -21 18 -42 40 l-39 40
42 0 42 0 0 -40z m210 -1124 l0 -1164 -30 -4 -30 -5 0 1169 0 1168 30 0 30 0
0 -1164z m-275 929 c55 -32 106 -65 113 -73 12 -16 17 -142 5 -142 -10 0 -206
111 -225 128 -26 22 -25 162 1 148 3 -2 51 -29 106 -61z m1060 -165 l90 -90
-30 -30 -30 -30 -89 89 -89 89 22 31 c12 17 26 31 29 31 4 0 48 -41 97 -90z
m-1053 -191 c57 -33 106 -66 110 -72 11 -17 10 -137 -1 -137 -12 0 -201 108
-223 128 -24 20 -26 142 -3 142 8 0 61 -27 117 -61z m1475 -231 c123 -123 223
-226 223 -230 0 -7 -64 -28 -84 -28 -6 0 -103 93 -217 207 l-208 206 27 33
c15 18 29 33 32 33 3 1 105 -99 227 -221z m-1477 -118 c57 -34 108 -66 112
-73 4 -7 8 -42 8 -79 l0 -67 -107 61 c-146 82 -143 79 -143 155 0 44 4 63 13
63 7 0 59 -27 117 -60z m2170 -575 l0 -326 -25 -24 -24 -25 -400 0 -400 0 4
33 c2 17 7 84 9 147 9 182 46 281 142 377 58 58 167 117 245 132 24 5 135 9
247 10 l202 1 0 -325z m255 300 l25 -24 0 -536 0 -535 -560 0 -560 0 0 140 0
140 408 0 407 0 51 24 c53 25 83 58 102 115 8 23 12 142 12 367 l0 334 45 0
c35 0 52 -6 70 -25z m-2328 -137 c22 -20 24 -30 21 -80 -2 -32 -7 -60 -10 -64
-6 -6 -189 96 -220 124 -14 12 -18 31 -18 82 l0 68 101 -54 c56 -29 113 -63
126 -76z m3385 -5 c12 -11 18 -30 18 -60 l0 -43 -64 0 c-63 0 -65 1 -71 28 -7
32 7 77 27 85 27 11 72 6 90 -10z m-3487 -283 c33 -18 75 -44 93 -57 30 -23
32 -27 32 -89 0 -43 -4 -64 -12 -64 -15 0 -221 119 -230 134 -4 6 -8 42 -8 79
l0 69 33 -20 c17 -10 59 -34 92 -52z m3619 9 c22 -17 26 -28 26 -70 l0 -49
-205 0 -205 0 0 54 c0 82 9 86 201 86 143 0 159 -2 183 -21z m193 -270 c15 -5
31 -16 35 -22 4 -7 8 -96 8 -199 l0 -188 -625 0 -625 0 0 210 0 210 589 0
c392 0 599 -4 618 -11z m-3849 -68 c42 -22 96 -53 120 -70 40 -30 42 -33 42
-84 0 -85 -6 -87 -101 -32 -147 83 -149 86 -149 161 0 35 2 64 5 64 3 0 40
-18 83 -39z m49 -412 c-18 -4 -53 -9 -79 -13 l-48 -6 0 55 0 55 79 -43 c78
-41 79 -42 48 -48z m3822 -93 c27 -24 27 -70 1 -96 -20 -20 -33 -20 -1260 -20
l-1240 0 0 70 0 70 1239 -2 c1202 -3 1240 -4 1260 -22z m-3559 -126 l0 -130
-240 0 -240 0 0 75 c0 73 1 75 27 81 15 4 122 29 237 55 116 26 212 48 214 48
1 1 2 -57 2 -129z m860 -156 c0 -49 -3 -57 -21 -61 -12 -4 -41 -16 -64 -28
-27 -15 -47 -20 -57 -14 -15 8 -44 66 -37 73 8 7 164 85 172 86 4 0 7 -25 7
-56z m2173 15 c45 -23 84 -43 86 -45 9 -8 -29 -74 -41 -74 -7 0 -40 11 -73 24
l-60 25 -3 55 c-2 31 0 56 3 56 3 0 42 -19 88 -41z m-1903 -19 l0 -50 -60 0
-60 0 0 50 0 50 60 0 60 0 0 -50z m830 0 l0 -50 -60 0 -60 0 0 50 0 50 60 0
60 0 0 -50z m840 0 l0 -50 -65 0 -65 0 0 50 0 50 65 0 65 0 0 -50z m-1390 -5
l0 -45 -65 0 -65 0 0 45 0 45 65 0 65 0 0 -45z m280 0 l0 -45 -65 0 -65 0 0
45 0 45 65 0 65 0 0 -45z m550 0 l0 -45 -65 0 -65 0 0 45 0 45 65 0 65 0 0
-45z m280 0 l0 -45 -65 0 -65 0 0 45 0 45 65 0 65 0 0 -45z m-2730 -180 l0
-65 -65 0 -65 0 0 65 0 65 65 0 65 0 0 -65z m809 39 c42 -53 17 -124 -44 -124
-19 0 -36 4 -39 9 -7 10 44 141 55 141 4 0 17 -12 28 -26z m2665 -35 c14 -33
26 -65 26 -70 0 -5 -18 -9 -40 -9 -41 0 -48 6 -70 65 -8 22 -6 30 15 51 13 13
28 24 33 24 5 0 21 -27 36 -61z m-2310 -5 c39 -6 44 -9 34 -21 -20 -24 -65
-43 -103 -43 l-35 0 0 -69 0 -68 40 -6 c23 -3 57 -18 78 -34 l37 -28 -94 -3
c-75 -2 -100 1 -129 16 -66 34 -92 125 -52 189 28 46 55 58 166 72 6 0 32 -2
58 -5z m606 -9 c0 -3 -16 -23 -36 -45 l-36 -40 -148 0 -148 0 -36 40 c-20 22
-36 42 -36 45 0 3 99 5 220 5 121 0 220 -2 220 -5z m551 -12 c-5 -10 -21 -30
-36 -45 l-26 -28 -144 0 c-140 0 -144 1 -174 26 -18 14 -36 35 -42 45 -9 18
-2 19 211 19 207 0 220 -1 211 -17z m569 8 c0 -5 -18 -25 -40 -45 l-40 -36
-139 0 c-136 0 -140 1 -170 26 -18 14 -36 35 -42 45 -9 18 -2 19 211 19 121 0
220 -4 220 -9z m354 -12 c80 -63 71 -186 -16 -231 -29 -15 -54 -18 -129 -16
l-94 3 50 33 c34 23 59 32 78 29 l27 -4 0 74 0 73 -37 0 c-26 0 -50 10 -77 30
l-40 30 106 0 c92 0 109 -3 132 -21z m-1689 -133 c11 0 38 4 60 8 49 9 94 -17
136 -79 l27 -40 -114 -3 c-63 -1 -166 -1 -230 0 l-117 3 34 45 c45 59 90 82
144 73 22 -4 49 -7 60 -7z m748 -56 l38 -55 -222 -3 c-122 -1 -224 0 -227 2
-5 5 32 70 56 99 14 16 32 17 166 15 l150 -3 39 -55z m560 0 l38 -55 -222 -3
c-122 -1 -224 0 -227 3 -3 2 12 30 32 60 l37 56 151 -3 152 -3 39 -55z m-2053
20 c29 -29 26 -81 -5 -110 -14 -13 -26 -22 -28 -19 -8 9 -57 131 -57 140 0 18
70 9 90 -11z m2690 15 c0 -14 -55 -135 -61 -135 -4 0 -19 14 -33 31 -25 29
-25 32 -11 66 8 19 15 37 15 39 0 2 20 4 45 4 25 0 45 -2 45 -5z m-3446 -144
c16 -65 24 -120 20 -135 l-6 -26 -134 0 -134 0 0 63 c0 59 20 148 41 190 8 14
24 17 98 17 l88 0 27 -109z m921 -41 c10 -11 36 -25 57 -32 l38 -11 0 -64 0
-64 -90 48 c-52 28 -90 54 -90 63 0 23 38 80 53 80 8 0 22 -9 32 -20z m2347
-27 c45 -43 37 -57 -56 -107 -45 -26 -86 -46 -90 -46 -3 0 -6 26 -6 58 0 62 1
63 80 104 43 23 39 23 72 -9z m-1982 -68 l0 -55 -60 0 -60 0 0 55 0 55 60 0
60 0 0 -55z m280 0 l0 -55 -65 0 -65 0 0 55 0 55 65 0 65 0 0 -55z m280 0 l0
-55 -65 0 -65 0 0 55 0 55 65 0 65 0 0 -55z m280 0 l0 -55 -65 0 -65 0 0 55 0
55 65 0 65 0 0 -55z m280 -20 l0 -55 -65 0 -65 0 0 55 0 55 65 0 65 0 0 -55z
m280 0 l0 -55 -65 0 -65 0 0 55 0 55 65 0 65 0 0 -55z m280 0 l0 -55 -65 0
-65 0 0 55 0 55 65 0 65 0 0 -55z"/>
<path d="M4140 3230 l0 -70 140 0 140 0 0 70 0 70 -140 0 -140 0 0 -70z"/>
<path d="M4840 3300 l0 -140 70 0 70 0 0 140 0 140 -70 0 -70 0 0 -140z"/>
<path d="M5057 3433 c-4 -3 -7 -66 -7 -140 l0 -133 70 0 70 0 0 140 0 140 -63
0 c-35 0 -67 -3 -70 -7z"/>
<path d="M5267 3433 c-4 -3 -7 -66 -7 -140 l0 -133 70 0 70 0 0 140 0 140 -63
0 c-35 0 -67 -3 -70 -7z"/>
</g>
`

const showel = `
<g transform="translate(-90, 400) scale(0.060000,-0.060000)"
fill="#000000" stroke="none">
<path d="M5196 5795 c-78 -20 -160 -65 -219 -121 -27 -25 -60 -49 -73 -54 -19
-8 -700 -187 -2356 -621 -124 -32 -230 -59 -236 -59 -5 0 -29 16 -53 35 -24
20 -68 42 -96 51 -48 14 -58 14 -107 0 -73 -21 -129 -82 -146 -157 -11 -52 -9
-65 69 -378 45 -178 79 -326 77 -328 -2 -2 -20 6 -39 17 -48 28 -144 41 -230
30 -81 -10 -121 -40 -138 -102 -6 -24 -9 -205 -7 -492 3 -441 4 -455 24 -482
47 -64 62 -69 230 -72 l154 -4 0 -82 0 -83 -123 -6 c-71 -3 -140 -12 -165 -21
-198 -71 -287 -290 -194 -478 38 -77 92 -130 171 -167 55 -25 61 -26 254 -26
197 0 199 0 218 24 24 30 24 62 0 92 -19 23 -24 24 -209 29 l-190 5 -44 30
c-122 85 -114 269 15 337 l42 23 835 3 c561 2 851 -1 884 -8 96 -20 156 -94
156 -190 0 -28 -6 -65 -14 -83 -17 -43 -76 -93 -123 -106 -24 -7 -228 -11
-566 -11 l-528 0 -24 -25 c-30 -29 -32 -64 -6 -96 l19 -24 537 -3 c583 -3 610
-1 693 52 58 36 119 111 143 175 17 45 20 72 17 137 -5 103 -38 174 -109 238
-73 65 -138 87 -286 93 l-123 6 0 83 0 82 153 4 c178 4 207 14 238 81 17 38
19 70 19 367 0 179 4 330 8 337 4 6 142 117 306 245 l299 234 106 -109 c98
-100 108 -108 140 -105 45 4 77 44 67 85 -5 19 -106 127 -307 329 -164 165
-299 307 -299 316 0 8 9 27 21 42 45 57 23 77 731 -631 l658 -658 -45 -45 -45
-45 -248 247 c-136 135 -257 251 -269 257 -33 17 -51 13 -78 -14 -51 -50 -47
-57 235 -340 143 -144 260 -264 260 -268 0 -3 -9 -29 -20 -57 -43 -110 -32
-233 30 -331 49 -75 236 -251 304 -284 117 -57 265 -42 374 39 82 60 470 458
486 498 20 47 20 91 1 128 -27 52 -73 77 -205 110 -261 65 -252 61 -359 164
-53 51 -106 98 -118 105 -49 25 -126 8 -167 -38 -11 -12 -25 -21 -32 -21 -17
0 -153 150 -160 178 -5 18 154 975 172 1035 3 9 30 45 60 80 145 165 177 359
90 542 -41 86 -88 141 -161 189 -110 73 -259 101 -379 71z m265 -177 c111 -58
173 -162 173 -288 1 -66 -4 -85 -32 -142 -37 -75 -83 -120 -157 -154 -58 -27
-149 -40 -184 -27 -22 9 -18 14 70 84 107 86 127 110 144 175 34 136 -70 270
-209 268 -62 -1 -107 -23 -204 -99 -68 -53 -72 -55 -72 -32 0 40 28 95 73 144
104 113 266 142 398 71z m-631 -266 c0 -56 -5 -94 -12 -104 -12 -14 -408 -326
-434 -341 -7 -4 -40 21 -77 57 -116 114 -224 126 -323 34 -99 -91 -92 -224 16
-322 23 -21 38 -41 34 -45 -35 -31 -297 -232 -299 -229 -1 2 -14 26 -28 54
-32 63 -89 115 -153 140 -45 17 -80 19 -439 19 -426 0 -426 0 -469 -58 -20
-27 -21 -43 -24 -293 l-3 -264 -42 0 -43 0 -97 392 c-53 216 -94 394 -91 397
6 6 2443 648 2467 650 15 1 17 -9 17 -87z m463 27 c36 -16 51 -54 35 -87 -11
-22 -698 -572 -736 -589 -13 -5 -102 72 -102 88 0 4 165 136 366 294 306 239
381 295 411 304 1 1 13 -4 26 -10z m-3144 -506 c16 -14 43 -107 126 -440 58
-233 105 -425 105 -428 0 -3 -28 -5 -63 -5 l-62 0 -105 421 -105 421 28 24
c33 29 48 30 76 7z m3051 -8 c49 -13 85 -16 143 -12 42 4 77 3 77 -1 1 -19
-146 -842 -150 -842 -3 0 -135 130 -294 289 l-288 288 193 152 c215 169 194
160 319 126z m-1011 -391 c23 -26 41 -50 40 -53 0 -3 -109 -91 -242 -194
l-242 -189 -3 78 c-2 42 -1 83 2 90 5 13 385 314 397 314 4 0 26 -21 48 -46z
m-1207 -341 c4 -468 -17 -443 368 -443 l240 0 0 -75 0 -75 -391 0 c-250 0
-397 4 -410 10 -18 10 -19 26 -19 465 l0 455 105 0 104 0 3 -337z m549 311
c54 -27 59 -57 59 -343 l0 -261 -230 0 -230 0 0 310 0 309 103 3 c153 4 269
-3 298 -18z m-1619 -384 c23 -7 46 -23 60 -43 53 -77 104 -128 147 -149 43
-22 61 -23 273 -26 l227 -4 3 -157 c3 -152 4 -159 30 -198 17 -25 45 -49 75
-64 48 -24 50 -24 456 -27 l407 -3 0 -89 0 -90 -900 0 -900 0 0 424 0 425 28
4 c49 7 57 7 94 -3z m4029 -632 c31 -15 126 -45 213 -66 87 -22 160 -43 164
-46 11 -11 -409 -425 -463 -457 -58 -33 -132 -39 -192 -14 -54 23 -231 200
-259 260 -55 116 -29 174 163 367 l147 147 86 -82 c49 -47 109 -93 141 -109z
m-2761 -453 l0 -85 -485 0 -485 0 0 85 0 85 485 0 485 0 0 -85z"/>
</g>
`

const waterTruck = `
<g transform="translate(-87 , 400) scale(0.0590,-0.059000)"
fill="#000000" stroke="none">
<path d="M3460 5749 c-766 -18 -1577 -55 -1649 -75 -134 -38 -243 -161 -278
-314 -6 -25 -9 -110 -8 -190 2 -80 3 -211 4 -292 l1 -148 27 -17 c23 -15 45
-17 138 -15 138 4 232 -14 342 -67 74 -36 82 -43 88 -73 3 -18 4 -188 3 -377
l-3 -345 -58 -23 -58 -23 -29 20 c-55 39 -72 31 -206 -101 -67 -67 -125 -132
-129 -146 -3 -14 1 -38 10 -55 8 -17 15 -41 15 -54 0 -46 -40 -114 -68 -114
-7 0 -27 -9 -43 -21 l-29 -20 0 -179 0 -179 53 -28 c42 -23 57 -38 76 -78 l24
-50 -22 -37 c-11 -20 -21 -47 -21 -59 0 -27 218 -252 261 -269 24 -10 35 -9
69 9 39 20 42 20 83 4 55 -21 77 -41 77 -70 0 -45 30 -60 133 -68 101 -8 250
1 279 16 10 5 23 28 29 50 20 67 104 98 166 62 18 -10 39 -14 54 -10 13 3 79
62 147 130 132 134 138 146 102 205 l-21 34 25 54 25 54 124 0 124 0 6 -119
c6 -130 14 -157 55 -168 35 -10 609 -10 644 0 42 11 51 41 56 170 l4 117 123
0 c135 0 132 1 158 -68 17 -41 16 -44 -5 -80 -14 -25 -19 -46 -15 -63 3 -13
62 -79 130 -147 135 -133 152 -141 207 -101 l29 22 53 -23 c58 -25 68 -34 68
-67 0 -13 8 -31 19 -42 24 -24 154 -36 287 -26 105 8 127 20 138 75 4 23 17
35 58 56 l52 26 42 -22 c26 -15 50 -21 65 -17 36 9 269 248 269 277 0 12 -9
38 -21 56 -20 33 -20 34 -1 76 10 24 25 48 33 55 10 8 106 13 330 15 281 3
318 5 342 21 l27 17 0 189 c0 219 -1 221 -88 221 l-52 0 0 224 c0 224 0 224
23 230 12 3 34 6 50 6 15 0 36 9 47 20 19 19 20 33 20 198 0 129 -4 183 -13
196 -18 24 -77 35 -189 36 l-98 0 0 115 c0 109 -2 121 -49 272 -55 174 -79
210 -130 198 -33 -7 -61 -40 -61 -70 0 -13 20 -84 44 -157 44 -131 52 -182 48
-300 l-2 -58 -740 0 c-407 0 -740 1 -740 3 0 20 425 1025 437 1035 13 9 105
12 379 12 199 0 369 -3 378 -6 9 -3 32 -58 57 -132 23 -70 51 -137 62 -149 43
-47 117 -18 117 45 0 58 -96 332 -139 399 -56 86 -167 186 -236 212 -207 78
-989 107 -2135 80z m1685 -138 c187 -13 333 -28 393 -43 33 -8 49 -20 77 -58
19 -26 35 -50 35 -54 0 -3 -159 -6 -352 -6 -214 0 -368 -4 -390 -11 -25 -6
-43 -19 -52 -37 -7 -15 -112 -263 -231 -552 -120 -289 -226 -541 -236 -561
-23 -46 -62 -63 -131 -55 -154 17 -224 18 -234 5 -6 -8 -30 -61 -53 -119 -70
-169 -43 -158 -206 -88 -77 33 -498 218 -935 411 -437 192 -822 361 -855 375
-53 23 -73 26 -165 24 -58 -1 -110 2 -117 6 -7 5 -13 83 -18 242 -9 291 -3
323 79 406 76 76 101 81 456 93 274 10 927 28 1275 35 304 6 1505 -3 1660 -13z
m-2540 -1233 l290 -128 -307 0 -308 0 0 135 c0 124 1 134 18 128 9 -4 148 -64
307 -135z m983 -431 c183 -81 345 -147 362 -147 42 0 61 26 114 158 26 64 55
123 64 130 12 9 139 12 557 12 298 0 598 -3 666 -7 l124 -6 145 -142 c147
-144 180 -166 230 -150 20 6 20 3 20 -224 l0 -231 -36 0 c-51 0 -67 12 -94 69
l-24 51 22 30 c42 56 34 72 -105 212 -70 71 -138 131 -151 135 -15 3 -33 -3
-57 -21 -34 -26 -34 -26 -77 -10 -54 21 -62 29 -77 81 -8 28 -19 44 -34 49
-37 13 -359 9 -384 -4 -14 -8 -24 -26 -28 -51 -7 -45 -13 -51 -73 -75 -42 -16
-44 -16 -73 5 -56 39 -70 32 -205 -102 -118 -118 -124 -126 -124 -164 0 -22 7
-51 16 -63 14 -20 13 -26 -4 -64 -10 -24 -26 -49 -35 -57 -21 -17 -215 -27
-237 -12 -11 8 -15 37 -16 131 -2 100 -5 125 -20 141 -16 18 -34 19 -354 19
-322 0 -338 -1 -355 -20 -14 -15 -18 -44 -23 -151 l-5 -132 -119 5 c-132 6
-135 7 -162 76 -16 41 -15 44 6 80 14 25 19 46 15 63 -9 36 -248 269 -277 269
-12 0 -37 -9 -55 -20 -31 -19 -32 -19 -80 -1 -55 21 -75 43 -75 83 0 17 -7 31
-19 38 -11 5 -76 10 -145 10 l-126 0 0 81 0 81 488 -4 487 -3 333 -148z m2732
67 l0 -77 -233 5 c-270 6 -279 8 -352 94 l-47 54 316 0 316 0 0 -76z m-3890
-248 c11 -31 53 -63 92 -71 17 -4 63 -23 102 -41 78 -38 90 -40 126 -20 24 12
28 10 76 -37 50 -47 51 -50 38 -76 -20 -37 -18 -48 20 -127 18 -39 37 -85 41
-102 8 -39 40 -81 71 -92 22 -9 24 -15 24 -79 0 -68 -1 -69 -30 -79 -31 -11
-36 -20 -86 -142 -61 -150 -59 -143 -42 -177 l16 -31 -48 -47 -47 -47 -41 12
c-36 11 -45 10 -98 -15 -33 -15 -83 -36 -113 -46 -60 -21 -89 -43 -102 -76 -8
-21 -15 -23 -78 -23 -67 0 -69 1 -81 30 -11 26 -77 70 -104 70 -5 0 -45 16
-89 36 -74 34 -83 36 -119 25 l-39 -12 -42 48 c-23 26 -41 54 -39 63 2 8 6 27
9 41 3 17 -5 45 -22 80 -15 29 -36 78 -46 108 -21 60 -42 89 -76 102 -21 8
-23 15 -23 79 0 64 2 71 23 79 34 13 55 42 76 102 10 30 31 79 46 109 26 51
27 58 16 99 -6 24 -10 45 -9 46 2 1 24 20 49 43 43 39 47 41 81 30 31 -9 44
-7 114 25 43 19 95 40 114 46 46 15 67 32 79 64 10 26 14 27 81 27 65 0 71 -2
80 -24z m2699 2 c13 -34 42 -56 102 -77 30 -10 79 -31 109 -45 63 -30 75 -31
111 -12 25 13 28 11 72 -39 44 -50 45 -54 35 -87 -9 -31 -7 -43 17 -94 15 -33
36 -83 46 -113 21 -60 43 -89 77 -102 20 -8 22 -15 22 -79 l0 -70 -34 -14
c-26 -11 -37 -24 -51 -62 -10 -27 -34 -84 -53 -128 -32 -73 -34 -82 -22 -119
12 -40 12 -40 -33 -84 -44 -45 -44 -45 -84 -33 -37 11 -46 10 -99 -15 -33 -15
-83 -36 -113 -46 -60 -21 -89 -43 -102 -76 -8 -21 -15 -23 -79 -23 -64 0 -71
2 -79 23 -13 33 -42 55 -102 76 -30 10 -80 31 -113 46 -53 25 -62 26 -98 15
l-41 -12 -47 47 c-46 45 -47 48 -34 74 19 36 18 48 -12 111 -14 30 -35 79 -45
109 -21 60 -43 89 -76 102 -21 8 -23 15 -23 79 0 64 2 71 23 79 33 13 55 42
76 102 10 30 31 79 45 109 30 63 31 75 12 111 -13 26 -12 29 34 74 l47 47 41
-12 c36 -11 45 -10 98 15 33 15 83 36 113 46 60 21 89 43 102 77 8 20 15 22
79 22 64 0 71 -2 79 -22z m1041 -198 l0 -220 -70 0 -70 0 0 220 0 220 70 0 70
0 0 -220z m-2250 -450 l0 -370 -220 0 -220 0 0 370 0 370 220 0 220 0 0 -370z
m-600 0 l0 -70 -70 0 -70 0 0 70 0 70 70 0 70 0 0 -70z m900 0 l0 -70 -70 0
-70 0 0 70 0 70 70 0 70 0 0 -70z m2100 0 l0 -70 -220 0 -220 0 0 70 0 70 220
0 220 0 0 -70z"/>
<path d="M2290 3488 c-119 -18 -224 -100 -278 -216 -23 -49 -27 -70 -27 -152
0 -87 3 -101 32 -161 85 -171 276 -250 460 -188 57 19 79 33 134 88 83 83 112
150 112 261 0 111 -29 178 -112 261 -94 94 -195 127 -321 107z m161 -169 c53
-27 105 -92 119 -150 33 -135 -75 -269 -215 -269 -132 0 -224 90 -225 218 0
171 170 278 321 201z"/>
<path d="M4972 3485 c-80 -18 -135 -50 -196 -114 -79 -85 -112 -193 -94 -308
13 -81 39 -135 98 -197 116 -123 320 -153 460 -68 112 69 180 190 180 322 0
111 -39 200 -122 277 -59 53 -94 71 -176 89 -78 16 -70 16 -150 -1z m173 -162
c81 -41 125 -112 125 -203 0 -130 -90 -220 -220 -220 -127 0 -223 94 -223 220
0 163 174 274 318 203z"/>
<path d="M5104 5142 c-12 -2 -31 -13 -42 -25 -22 -25 -261 -595 -269 -643 -4
-23 0 -37 15 -52 27 -27 160 -33 628 -29 324 2 363 4 387 20 20 13 27 26 27
50 0 53 -189 628 -216 656 l-23 25 -243 0 c-134 1 -253 0 -264 -2z m385 -150
l44 -7 73 -217 72 -218 -350 0 c-276 0 -349 3 -345 13 62 159 174 415 186 425
16 13 240 16 320 4z"/>
</g>
`

const tower = `
<g transform="translate(-140, 460) scale(0.075000,-0.077000)"
fill="#000000" stroke="none">
<path d="M4030 5725 l0 -195 -30 0 -30 0 0 75 0 75 -40 0 -40 0 0 -75 0 -75
-300 0 -300 0 0 83 c0 51 -5 88 -12 95 -8 8 -46 12 -105 12 -81 0 -94 -2 -103
-19 -6 -13 -10 -169 -10 -438 0 -346 2 -423 14 -439 12 -16 27 -19 98 -19 121
0 118 -2 118 105 l0 90 200 0 c110 0 200 -1 200 -2 0 -2 -25 -181 -55 -398
-30 -217 -55 -401 -55 -407 0 -9 -16 -13 -45 -13 l-45 0 0 38 c0 133 -94 236
-201 220 l-24 -3 -3 -284 c-2 -201 1 -288 9 -297 27 -33 129 1 172 57 29 39
47 97 47 152 0 36 1 37 38 37 l38 0 -37 -270 c-20 -148 -40 -274 -44 -280 -3
-5 -16 -10 -29 -10 -81 0 -188 -76 -231 -164 -74 -151 -15 -325 137 -402 40
-20 47 -28 43 -47 -4 -21 -105 -748 -105 -761 0 -3 -147 -7 -327 -8 l-328 -3
0 -35 0 -35 1355 0 1355 0 3 38 3 37 -330 0 -330 0 -6 43 c-14 103 -147 1070
-158 1146 -10 78 -10 85 7 95 83 49 112 75 138 125 31 60 37 142 16 214 -12
41 -84 127 -106 127 -6 0 -18 -13 -26 -29 -14 -26 -14 -30 5 -47 67 -60 82
-158 36 -236 -58 -100 -196 -119 -280 -40 -42 39 -57 75 -57 132 0 89 46 151
132 179 20 7 41 18 48 26 8 10 1 88 -30 311 -22 165 -43 309 -45 322 -4 17 -1
22 14 22 15 0 22 -9 27 -35 13 -73 77 -132 160 -149 60 -13 64 0 64 224 0 172
-2 201 -17 215 -19 19 -53 15 -110 -12 -43 -20 -88 -80 -97 -128 -6 -29 -11
-35 -31 -35 -14 0 -25 4 -25 9 0 8 -17 128 -45 324 l-5 37 200 0 200 0 0 -82
c0 -46 5 -90 10 -98 13 -20 179 -21 203 -1 15 12 17 55 17 438 0 273 -4 431
-10 444 -9 17 -22 19 -103 19 -59 0 -97 -4 -105 -12 -7 -7 -12 -44 -12 -95 l0
-83 -300 0 -300 0 0 195 0 195 -40 0 -40 0 0 -195z m-820 -161 c0 -123 -21
-114 280 -114 161 0 260 -4 260 -10 0 -14 -40 -312 -46 -337 -4 -23 -5 -23
-209 -23 l-205 0 0 131 0 130 -37 -3 -38 -3 -3 -222 -2 -223 -35 0 -35 0 0
375 0 375 35 0 35 0 0 -76z m1650 -299 l0 -375 -35 0 -35 0 0 375 0 375 35 0
35 0 0 -375z m-782 176 c-3 -10 -76 -91 -81 -91 -2 0 -23 23 -46 50 l-42 50
86 0 c48 0 85 -4 83 -9z m632 -176 l0 -185 -205 0 -204 0 -6 33 c-7 41 -45
319 -45 329 0 5 104 8 230 8 l230 0 0 -185z m-790 28 c0 -5 -29 -40 -65 -78
-39 -41 -64 -62 -61 -50 2 11 10 69 17 129 l13 108 48 -50 c26 -28 48 -54 48
-59z m269 -3 c7 -52 15 -104 17 -115 3 -12 -23 10 -60 50 -36 39 -66 72 -66
75 0 14 84 100 90 93 4 -4 13 -51 19 -103z m-120 -130 l73 -80 -147 0 -147 0
73 80 c39 44 73 80 74 80 1 0 35 -36 74 -80z m66 -169 c-46 -40 -120 -91 -135
-91 -15 0 -89 51 -134 91 -6 5 53 9 134 9 81 0 140 -4 135 -9z m-284 -88 c43
-32 78 -60 79 -63 0 -9 -191 -152 -196 -147 -6 5 28 267 34 267 3 0 40 -26 83
-57z m402 -74 c10 -73 15 -134 13 -136 -5 -6 -196 138 -195 147 0 6 151 120
159 120 4 0 14 -59 23 -131z m-149 -119 l100 -75 -102 -3 c-56 -1 -148 -1
-203 0 l-101 3 98 77 c55 42 101 76 104 75 3 -1 50 -36 104 -77z m456 -120 c0
-71 -2 -130 -4 -130 -40 0 -66 52 -66 130 0 78 23 125 63 129 4 1 7 -57 7
-129z m-460 -95 c-47 -30 -92 -55 -100 -55 -8 0 -53 25 -100 55 l-85 54 185 0
185 0 -85 -54z m-284 -42 c58 -36 101 -70 97 -74 -11 -11 -247 -159 -249 -156
-2 4 40 297 43 297 2 0 51 -30 109 -67z m501 -80 c11 -82 20 -149 19 -150 -2
-3 -238 145 -248 155 -6 6 32 36 94 75 57 36 105 66 108 66 3 1 15 -65 27
-146z m-921 -78 c9 -23 14 -74 14 -155 0 -130 -12 -180 -49 -200 -21 -11 -21
-10 -21 200 0 210 0 211 21 200 11 -7 27 -27 35 -45z m716 -28 c53 -34 105
-67 115 -74 14 -9 -34 -12 -227 -12 -193 0 -241 3 -227 12 161 104 214 137
227 137 8 0 58 -29 112 -63z m98 -171 c0 -3 -47 -30 -105 -61 l-105 -56 -112
60 -113 60 218 1 c119 0 217 -2 217 -4z m-308 -161 c0 -9 -291 -169 -297 -164
-2 2 6 73 17 156 l21 152 129 -68 c71 -37 130 -72 130 -76z m467 43 c1 -22
-11 -35 -64 -72 -36 -25 -71 -46 -76 -46 -17 0 -141 72 -134 78 3 3 63 36 133
73 l127 68 7 -37 c4 -20 7 -49 7 -64z m-281 -137 c102 -52 113 -61 106 -88
l-6 -23 -236 0 c-141 0 -232 4 -227 9 16 15 259 141 273 141 7 0 47 -18 90
-39z m102 -209 c0 -12 -27 -30 -95 -61 l-95 -43 -47 20 c-27 11 -86 39 -133
61 l-85 40 228 1 c211 0 227 -1 227 -18z m-448 -73 c84 -39 155 -73 157 -75 2
-2 -31 -19 -74 -39 l-77 -35 -43 43 c-23 24 -62 51 -86 62 -24 10 -45 20 -47
21 -4 3 9 94 13 94 2 0 72 -32 157 -71z m524 -50 c35 -37 86 -65 137 -74 44
-9 43 -7 52 -72 l7 -53 -74 34 c-40 18 -122 57 -183 86 l-110 52 60 29 c33 16
65 29 71 29 5 0 23 -14 40 -31z m-693 -58 c39 -15 96 -70 119 -114 31 -61 23
-161 -17 -219 -35 -50 -120 -98 -174 -98 -128 0 -231 103 -231 230 0 76 66
169 140 199 32 13 132 14 163 2z m588 -87 c79 -37 148 -71 154 -75 5 -5 -109
-9 -257 -9 l-266 0 -5 28 c-3 15 -4 28 -3 29 11 12 196 92 212 92 12 1 86 -29
165 -65z m128 -168 c-9 -9 -275 -116 -289 -116 -8 0 -59 18 -112 41 -75 31
-98 46 -98 60 0 18 12 19 252 19 138 0 249 -2 247 -4z m211 -159 c12 -81 20
-154 18 -162 -2 -10 -56 8 -198 67 -107 44 -198 83 -202 86 -5 6 352 162 359
158 1 -1 11 -68 23 -149z m-662 20 c29 -11 49 -24 45 -28 -4 -4 -91 -42 -193
-84 -102 -42 -191 -79 -198 -82 -10 -4 -13 4 -10 32 3 37 3 38 69 54 76 19
135 55 176 106 18 23 33 33 43 29 8 -3 39 -15 68 -27z m348 -144 l164 -68
-175 -3 c-96 -1 -254 -1 -350 0 l-175 3 163 67 c89 37 172 68 185 68 13 0 97
-30 188 -67z m143 -167 c-2 -2 -74 -29 -159 -61 -107 -39 -164 -55 -185 -52
-16 3 -93 30 -170 60 l-140 56 329 0 c181 1 327 -1 325 -3z m-600 -106 c85
-31 150 -60 145 -62 -20 -13 -448 -168 -454 -166 -4 2 31 297 39 325 1 2 27
-7 59 -19 31 -12 126 -47 211 -78z m835 -69 c12 -89 19 -161 15 -161 -9 0
-439 159 -453 167 -5 3 85 41 200 83 115 43 211 76 212 75 2 -2 14 -76 26
-164z m-364 -107 c96 -37 177 -68 179 -70 2 -2 -171 -4 -385 -3 l-389 0 180
68 c99 38 193 69 210 70 16 1 108 -29 205 -65z m404 -191 c3 -16 8 -47 11 -70
l7 -43 -622 0 -622 0 7 53 c3 28 9 60 11 70 5 16 42 17 604 17 l598 0 6 -27z"/>
<path d="M3411 3368 c-49 -33 -73 -83 -68 -137 15 -143 194 -189 275 -70 20
28 23 43 20 92 -4 51 -9 63 -41 94 -31 32 -43 37 -94 41 -49 3 -64 0 -92 -20z
m131 -75 c42 -37 9 -123 -47 -123 -33 0 -75 42 -75 75 0 55 79 87 122 48z"/>
</g>
`

const frontLoader = `
<g transform="translate(-90, 400) scale(0.060000,-0.060000)"
fill="#000000" stroke="none">
<path d="M2665 5718 c-43 -25 -44 -34 -45 -276 l0 -234 29 -29 c26 -26 36 -29
90 -29 l61 0 0 -395 0 -395 -180 0 -180 0 0 128 c0 102 -4 135 -19 168 -27 60
-235 262 -278 270 -38 7 -73 -11 -99 -51 -30 -47 -13 -83 96 -198 l100 -105 0
-111 0 -111 -67 2 c-41 0 -82 -5 -105 -15 -29 -12 -322 -214 -486 -334 -65
-48 -63 -22 -60 -669 l3 -586 28 -24 c28 -24 31 -24 231 -24 221 0 215 2 235
-61 8 -24 51 -74 139 -161 l127 -125 115 -32 c218 -59 212 -59 349 -23 67 17
151 45 187 63 82 39 175 130 250 245 l52 81 78 6 c99 9 458 9 558 0 l77 -6 39
-66 c58 -100 199 -233 291 -275 163 -76 328 -83 516 -25 l102 32 115 109 c88
83 122 123 145 170 17 34 31 72 31 85 0 12 3 23 7 23 4 0 111 -103 238 -229
133 -133 241 -232 255 -236 14 -3 181 -5 372 -3 l346 3 26 24 26 24 0 218 0
218 -42 34 c-50 40 -84 58 -155 81 -29 10 -60 27 -68 39 -9 12 -34 81 -56 152
-169 539 -169 539 -200 568 -44 40 -104 32 -153 -22 -11 -12 -22 -21 -27 -21
-4 0 -136 164 -293 364 -157 200 -297 371 -311 380 -28 19 -339 96 -385 96
-35 0 -82 -18 -312 -121 -97 -43 -182 -80 -188 -81 -8 -2 -52 155 -129 459
l-117 461 78 4 c111 5 114 8 143 199 14 85 28 186 31 226 13 145 1 153 -236
153 -191 0 -222 -8 -244 -61 -16 -39 -10 -72 19 -99 30 -28 81 -39 179 -40
l79 0 -6 -37 c-3 -21 -9 -62 -13 -90 l-7 -53 -613 0 -614 0 0 90 0 90 74 0
c167 0 216 23 216 104 0 37 -5 48 -31 70 -30 26 -31 26 -212 26 -118 -1 -189
-5 -202 -12z m685 -1048 l0 -480 -170 0 -170 0 0 480 0 480 170 0 170 0 0
-480z m586 21 c62 -244 116 -456 119 -472 l7 -29 -256 0 -256 0 0 475 0 476
137 -3 137 -3 112 -444z m992 -495 c112 -29 130 -40 190 -118 26 -33 145 -185
265 -337 119 -151 217 -278 217 -282 0 -9 -237 -209 -248 -209 -5 0 -94 154
-198 341 -104 188 -201 354 -216 370 -48 53 -68 46 -339 -122 -371 -230 -327
-211 -486 -217 -115 -4 -135 -3 -128 9 18 30 273 355 292 372 11 10 119 62
239 117 250 113 260 115 412 76z m-2164 -42 l46 -7 0 -283 c0 -156 -3 -284 -7
-284 -5 0 -41 9 -82 20 -41 11 -89 20 -106 20 -31 0 -269 -61 -314 -80 -42
-19 -239 -212 -266 -261 -25 -46 -76 -235 -90 -331 l-7 -48 -109 0 -109 0 2
482 3 482 203 143 204 142 86 4 c173 6 500 7 546 1z m1180 -251 c-38 -49 -101
-130 -141 -180 l-73 -93 -360 0 -360 0 0 180 0 180 501 0 501 0 -68 -87z m975
-298 c45 -80 81 -149 81 -152 0 -4 -17 9 -37 29 -51 49 -85 64 -215 98 -61 16
-113 32 -117 35 -3 4 1 11 9 15 8 5 53 34 100 64 47 30 88 55 92 55 3 1 43
-64 87 -144z m-2181 -203 c150 -47 285 -187 334 -345 45 -150 0 -304 -126
-431 -110 -112 -237 -164 -371 -153 -235 20 -453 282 -431 517 13 141 126 296
272 372 116 61 214 73 322 40z m1324 -30 c-81 -84 -120 -178 -158 -383 l-16
-89 -293 0 c-271 0 -294 1 -299 18 -2 9 -14 66 -26 125 -36 181 -65 252 -137
329 l-46 48 511 0 511 0 -47 -48z m611 22 c51 -14 114 -38 141 -52 103 -56
208 -230 214 -358 5 -87 -21 -224 -53 -279 -49 -86 -166 -176 -272 -210 -72
-24 -207 -17 -286 14 -78 31 -175 98 -213 148 -17 23 -46 76 -65 120 -43 101
-46 197 -9 318 21 69 33 88 93 155 83 92 122 116 235 145 111 30 107 30 215
-1z m1221 -206 c36 -112 77 -243 92 -292 35 -119 69 -155 179 -193 101 -36
110 -50 101 -156 l-8 -77 -251 0 -252 0 -230 230 c-229 229 -230 230 -210 250
19 18 507 438 512 439 1 1 32 -90 67 -201z"/>
<path d="M2532 3226 c-125 -41 -202 -149 -202 -283 1 -143 93 -252 239 -284
100 -21 228 35 289 127 97 145 43 343 -113 419 -65 32 -152 41 -213 21z m149
-215 c53 -54 29 -138 -45 -157 -67 -16 -129 54 -106 120 24 69 100 88 151 37z"/>
<path d="M4495 3227 c-67 -23 -101 -44 -143 -92 -164 -187 -31 -479 218 -478
251 1 381 296 214 482 -72 80 -197 118 -289 88z m136 -216 c56 -56 27 -145
-52 -157 -28 -5 -41 -1 -67 21 -27 22 -32 34 -32 70 0 36 5 48 31 69 41 35 83
34 120 -3z"/>
<path d="M3385 5706 c-32 -32 -40 -69 -25 -107 17 -39 46 -59 90 -59 44 0 73
20 90 59 26 64 -20 131 -90 131 -30 0 -47 -6 -65 -24z"/>
</g>
`

const marker = `
<g transform="translate(-130.000000,430.000000) scale(0.070000,-0.070000)"
fill="#000000" stroke="none">
<path d="M3885 6043 c-261 -22 -530 -116 -755 -264 -127 -84 -334 -290 -419
-419 -123 -185 -207 -391 -247 -605 -26 -139 -27 -444 -1 -565 50 -232 155
-451 359 -743 210 -299 1031 -1384 1094 -1444 76 -73 95 -72 176 9 35 35 184
227 333 428 148 201 397 537 552 747 318 429 349 476 423 622 139 279 186 555
145 864 -46 351 -179 633 -417 882 -277 290 -609 449 -1010 485 -110 10 -151
10 -233 3z m240 -243 c270 -26 493 -123 727 -317 301 -249 460 -602 461 -1018
1 -202 -34 -357 -115 -519 -81 -161 -598 -884 -1097 -1534 l-102 -133 -132
173 c-353 465 -912 1227 -976 1332 -193 314 -249 611 -181 945 110 539 458
902 995 1036 84 21 147 30 295 44 8 0 65 -4 125 -9z"/>
<path d="M3840 5652 c-53 -27 -80 -46 -80 -55 0 -7 94 -152 111 -171 5 -5 14
-4 24 4 13 11 15 -21 15 -314 l0 -326 -270 0 -270 0 -10 -22 c-18 -45 -152
-705 -157 -778 -9 -118 -34 -110 368 -110 l339 0 0 -75 0 -75 -109 0 c-129 0
-141 -7 -141 -76 l0 -45 -125 2 c-116 2 -128 1 -155 -20 -22 -15 -30 -30 -30
-51 0 -35 6 -36 40 -10 24 19 45 20 610 20 565 0 586 -1 610 -20 34 -26 40
-25 40 9 0 22 -8 36 -32 52 -30 22 -40 23 -155 20 l-123 -3 0 51 c0 66 -11 71
-144 71 l-106 0 0 75 0 75 338 0 c267 0 342 3 356 13 16 12 18 24 14 93 -5 76
-138 735 -158 782 l-10 22 -275 0 -275 0 0 330 0 330 25 -16 c14 -9 27 -15 29
-13 1 2 28 42 60 89 l58 84 -34 28 c-75 63 -160 56 -195 -17 -15 -30 -15 -42
-5 -80 8 -31 12 -154 12 -390 l0 -345 -30 0 -30 0 0 344 c0 281 3 350 15 379
39 94 -56 186 -145 139z m85 -57 c14 -13 25 -31 25 -40 0 -16 -43 -65 -57 -65
-4 0 -22 22 -40 47 l-32 47 27 18 c36 24 48 23 77 -7z m235 7 l23 -19 -32 -46
c-18 -26 -35 -47 -38 -47 -17 0 -53 46 -53 67 0 28 33 63 59 63 10 0 29 -8 41
-18z m-400 -909 c0 -21 -3 -62 -6 -90 l-7 -53 -183 0 c-169 0 -184 1 -184 18
1 25 26 137 34 150 5 8 59 12 177 12 l169 0 0 -37z m420 -15 c0 -29 3 -70 6
-90 l7 -38 -193 0 -192 0 6 31 c3 17 6 58 6 90 l0 59 180 0 180 0 0 -52z m406
41 c4 -6 15 -47 24 -90 l18 -79 -188 0 -187 0 -7 61 c-3 34 -6 75 -6 90 l0 29
169 0 c115 0 172 -4 177 -11z m-846 -308 c0 -109 17 -101 -215 -101 l-202 0
14 63 c7 34 16 72 18 85 5 22 7 22 195 22 l190 0 0 -69z m455 47 c2 -13 6 -53
7 -91 l3 -67 -202 0 -203 0 0 68 c0 122 -17 112 201 112 187 0 189 0 194 -22z
m466 -65 l16 -83 -202 0 -202 0 -7 31 c-3 17 -6 56 -6 85 l0 54 192 -2 192 -3
17 -82z m-935 -190 c-3 -21 -6 -62 -6 -90 l0 -53 -104 0 c-108 0 -133 -9 -120
-44 6 -15 62 -16 620 -16 l614 0 0 -25 0 -25 -730 0 -730 0 0 25 c0 24 2 25
75 25 58 0 77 3 81 15 12 31 -15 45 -87 45 l-69 0 6 28 c3 15 10 43 14 62 4
19 11 47 14 63 l6 27 211 0 212 0 -7 -37z m488 -15 c3 -29 6 -70 6 -90 l0 -38
-220 0 -220 0 0 29 c0 15 3 56 6 90 l7 61 207 0 207 0 7 -52z m493 -18 c7 -36
15 -75 18 -87 l5 -23 -225 0 -225 0 0 59 c0 32 -3 73 -6 90 l-7 31 214 -2 213
-3 13 -65z m-677 -365 c0 -73 -1 -75 -25 -75 -24 0 -25 2 -25 75 0 73 1 75 25
75 24 0 25 -2 25 -75z m250 -170 l0 -25 -275 0 -275 0 0 25 0 25 275 0 275 0
0 -25z"/>
<path d="M3272 3784 c-30 -21 -28 -27 18 -58 l40 -26 0 50 c0 55 -15 64 -58
34z"/>
<path d="M3357 3793 c-11 -11 -8 -83 4 -83 18 0 69 41 69 55 0 24 -56 46 -73
28z"/>
<path d="M4588 3789 c-26 -15 -22 -35 11 -58 16 -12 32 -21 35 -21 3 0 6 20 6
45 0 48 -14 57 -52 34z"/>
<path d="M4670 3749 l0 -50 31 22 c17 12 33 28 36 37 7 17 -22 42 -49 42 -15
0 -18 -8 -18 -51z"/>
<path d="M3250 3691 c0 -21 9 -36 34 -55 43 -33 46 -32 46 3 0 21 -9 36 -34
55 -43 33 -46 32 -46 -3z"/>
<path d="M3387 3693 c-28 -20 -37 -33 -37 -55 0 -34 4 -34 47 -5 26 17 33 29
33 55 0 17 -1 32 -3 32 -1 0 -19 -12 -40 -27z"/>
<path d="M4560 3697 c0 -27 14 -47 50 -71 33 -21 40 -20 40 7 0 28 -7 38 -47
65 -39 28 -43 27 -43 -1z"/>
<path d="M4701 3692 c-25 -21 -32 -33 -29 -55 l3 -27 38 25 c29 20 37 32 37
55 0 37 -7 37 -49 2z"/>
<path d="M3250 3591 c0 -21 8 -35 28 -49 51 -35 52 -35 52 2 0 28 -6 38 -34
55 -43 27 -46 27 -46 -8z"/>
<path d="M4703 3599 c-27 -17 -33 -27 -33 -56 0 -36 1 -36 53 -1 19 14 27 28
27 49 0 34 -5 35 -47 8z"/>
<path d="M3250 3496 c0 -28 6 -38 40 -60 l40 -26 0 34 c0 28 -6 38 -40 60
l-40 26 0 -34z"/>
<path d="M4707 3503 c-30 -21 -37 -32 -37 -60 l0 -34 40 28 c32 23 40 34 40
61 0 17 -1 32 -3 32 -1 0 -19 -12 -40 -27z"/>
<path d="M3383 3494 c-21 -16 -33 -34 -33 -50 0 -29 13 -30 51 -3 21 14 29 29
29 50 0 35 -6 36 -47 3z"/>
<path d="M4560 3496 c0 -25 53 -76 78 -76 26 0 12 49 -21 74 -42 32 -57 33
-57 2z"/>
<path d="M3250 3402 c0 -33 27 -62 58 -62 34 0 27 34 -12 64 -43 33 -46 32
-46 -2z"/>
<path d="M3388 3402 c-47 -31 -51 -62 -9 -62 36 0 53 20 49 58 l-3 29 -37 -25z"/>
<path d="M4564 3416 c-3 -8 0 -27 8 -43 10 -21 21 -29 46 -31 47 -5 43 28 -8
62 -33 22 -41 24 -46 12z"/>
<path d="M4703 3404 c-40 -31 -44 -64 -10 -64 31 0 57 29 57 63 0 33 -6 33
-47 1z"/>
</g>
`

const ptp = `
<g transform="translate(-10.000000,0.000000) scale(14,14)" fill="#00FF00" stroke="none"> 
<path d="M12 4C16.41 4 20 7.59 20 12S16.41 20 12 20 4 16.41 4 12 7.59 4 12 4M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M8.43 14.44L7.07 13.83C7.35 13.27 7.5 12.67 7.5 12.05C7.5 11.39 7.35 10.76 7.07 10.17L8.43 9.56C8.81 10.37 9 11.2 9 12.05C9 12.8 8.81 13.6 8.43 14.44M11.53 15.94L10.22 15.29C10.75 14.16 11 13 11 11.86C11 10.73 10.75 9.69 10.22 8.71L11.53 7.96C12.19 9.12 12.5 10.42 12.5 11.86C12.5 13.33 12.19 14.69 11.53 15.94M14.68 17.35L13.31 16.65C14.1 15.11 14.5 13.57 14.5 12S14.1 8.87 13.31 7.31L14.68 6.65C15.55 8.43 16 10.22 16 12C16 13.82 15.55 15.6 14.68 17.35Z" />
</g>
`

export default {
    solarTrailer,
    pmpap,
    pmpsm,
    haultruck,
    driller,
    showel,
    waterTruck,
    tower,
    frontLoader,
    marker,
    ptp
}