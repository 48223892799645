import { STRINGS, NUMBERS } from '@/interfaces/SmartLink/General';

const zonaMultiple = [
    {
        value: -100,
        color: '#7cb5ec'
      }, 
      {
        value: -90,
        color: '#e69a5c'
      }, 
      {
        color: '#e3272a'
  }
]

interface  Zone {
    value?: number;
    color: string;
}
interface  Zones {
    [key: string]: Zone[];
}

const ZONES:Zones  = {
    Latency     : [
        {
            value: 0,
            color: '#000000'
        },
        {
            value: 100,
            color: '#eecaca'
        }, 
        {
            color: '#FF3333'
        }
    ],
    snmp     : [
        {
            value: -75,
            color: '#FF6961'
        },
        {
            value: -65,
            color: '#FDFD96'
        }, 
        {
            color: '#06CB03'
        }
    ],
    wlan0noise  : zonaMultiple,
    wlan1noise  : zonaMultiple,
    wlan2noise  : zonaMultiple,
    wlan3noise  : zonaMultiple,
    Status      : [
                    {
                    value: 1,
                    color: '#5a5b5e'
                    },                    
                    {
                    value: 2,
                    color: '#ea9999'
                    },
                    {
                    value: 3,
                    color: '#ffd966'
                    },
                    {
                    value: 4,
                    color: '#93c47d'
                    },
                  ],
    temperature : [       
        {
            value: -40,
            color: '#031BCB'
        },
        {
            value: 70,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    PRG : [
        {
            value: 20,
            color: '#031BCB'
        },
        {
            value: 58,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    LX5 : [
        {
            value: 18,
            color: '#031BCB'
        },
        {
            value: 45,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    OSP : [
        {
            value: 9,
            color: '#031BCB'
        },
        {
            value: 28,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    JR3 : [
        {
            value: 9,
            color: '#031BCB'
        },
        {
            value: 28,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    HNT : [
        {
            value: 8,
            color: '#031BCB'
        },
        {
            value: 45,
            color: '#06CB03'
        },
        {
            color: '#CB0F03'
        },
    ],
    Cost        : [
        {
            value: 5000,
            color: '#22FB00'
        },
        {
            value: 10000,
            color: '#F7FB00'
        },
        {
            color: '#FB0B00'
        },
    ],
    predictsnr:[
        {
            value: 20,
            color: '#FA1717'
        },
    ],
    predict:[
        {
            value: -75, 
            color: '#FA1717'               
        },
        
    ],
}

const POTLINE:NUMBERS = {
    Latency : 100,
    PRG: 58,
    LX5: 45,
    JR3: 28,
    OSP: 28,
    HNT: 45,
    predict : -75,
    snmp2   : -65,
    predictsnr: 20,
    'Predict vs Real' : -70,
}

const TYPE_GRAPH:STRINGS = {
    Status:     'column',
    rssi:       'column',
    rsrp:       'column',
    rsrq:       'column',
}

const YAXISLABEL:STRINGS = { 
    Latency     : 'Milliseconds',
    wlan0noise  : 'dB',
    wlan1noise  : 'dB',
    wlan2noise  : 'dB',
    wlan3noise  : 'dB',
    cpu         : '%',
    memoria     : '%',
    disco       : '%',
    predict     : 'dBm'
}


export default {
    ZONES,
    YAXISLABEL,
    POTLINE,
    TYPE_GRAPH,
}