import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { vuetify } from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { loadFonts } from './plugins/webfontloader'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import i18n from '../i18n/i18n'

import './leaflet/leaflet.css';
import './leaflet/leaflet.js'; // Ruta a tu archivo local
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Highcharts from "highcharts";
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsMore from 'highcharts/highcharts-more'
import accessibility from 'highcharts/modules/accessibility';
import Stock from "highcharts/modules/stock";
import exportingInit from "highcharts/modules/exporting";
import exportingData from "highcharts/modules/export-data";
import drilldown from 'highcharts/modules/drilldown';
import mapInit from 'highcharts/modules/map';
import networkgraph from 'highcharts/modules/networkgraph';
import KProgress from 'k-progress-v3';
// import VueMapboxTs from "vue-mapbox-ts";
import html2pdf from "html2pdf.js";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


loadFonts()
Stock(Highcharts);
exportingInit(Highcharts);
exportingData(Highcharts);
accessibility(Highcharts);
Highcharts3D(Highcharts);
HighchartsMore(Highcharts);
drilldown(Highcharts);
mapInit(Highcharts);
networkgraph(Highcharts);

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(vuetify)
  .use(VueAxios,axios)
//   .use(VueMapboxTs)
  .use(html2pdf)
  .component('k-progress', KProgress)
  .component('VueDatePicker', VueDatePicker)
  .component('EasyDataTable', Vue3EasyDataTable)
  .use(VueGoogleMaps, {
      load: {
          // key: 'AIzaSyCXD3HolR8EmuKo6Yz0sxGVIFrtxt1lyWM',  // cuenta Cesar
          key: '',  // cuenta Cesar
          language: 'es',
      },
      installComponents: true
  })
  .mount('#app')
